#report-to-printExp{
    display: block;
}
@media print {
    @page {
        size: portrait !important;
        margin: 4%;
     }
     
     
    body {
      visibility: hidden;
    }
    #report-to-printExp {
        width: 100%;
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
      scale: (1.2);
      display: block !important;
    }
    .holidayBG{
        background-color: var(--danger);
    }

 td{page-break-inside: avoid;  
    page-break-after: auto;} 

    #print-button{
        visibility: hidden;
    }
    .no-border-right{
        border-right: 1px solid transparent !important;
    } 
    .no-border-bottom{
        border-bottom: 1px solid transparent !important;
    }
  }